<template>
  <div
    v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
    class="ecommerce-application"
    style="height: inherit"
  >
    <!-- Filters -->
    <cameraconsole-list-filters
      :user-data.sync="userData"
      :client-filter.sync="clientFilter"
      :clients-list="clientsList"
      :view-type-filter.sync="viewTypeFilter"
      :view-type-options="viewTypeOptions"
      :site-filter.sync="siteFilter"
      :site-options="siteOptions"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />
    <!-- Table Top -->
    <!-- Per Page and Search -->
    <b-row class="mt-2">
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>Show</label>
        <v-select
          v-model="perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>
        <b-button
          class="ml-2"
          variant="primary"
          :disabled="disableRefresh"
          @click="refreshTheList"
        >
          <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
        </b-button>
      </b-col>

      <!-- Search Field -->
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
        </div>
      </b-col>
    </b-row>

    <!-- Cameras List - All Cameras by Site -->
    <template
      v-if="viewTypeFilter === 'cameraBySites'"
    >
      <!-- Images and Videos List -->
      <div
        v-for="siteItem in cameras"
        :key="'theSite-' + siteItem.sitename"
        class="ml-1 mr-1"
      >
        <!-- Date Sub-Header -->
        <b-row class="mt-2 mb-1 border-bottom-primary">
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h5 class="mb-0">
              <h4>{{ siteItem.sitename }}</h4>
            </h5>
          </b-col>
        </b-row>
        <section class="grid-view">
          <b-card
            v-for="camera in siteItem.cameras"
            :key="camera.camera_id"
            no-body
            :class="camera.latest_alert !== undefined && camera.latest_alert_show && 'border-danger'"
          >
            <div class="item-img text-center">
              <b-link :to="{ name: 'apps-cameraconsole-edit', params: { cameraid: camera.camera_id } }">
                <!-- <b-img-lazy
                  v-if="camera.latest_image !== undefined && camera.latest_image.thumb_url !== ''"
                  :alt="`${camera.sitename}-${camera.location_name}`"
                  blank-src="@/assets/images/noImage.png"
                  fluid
                  class="card-img-top"
                  :src="(camera.latest_alert !== undefined && camera.latest_alert_show) ? camera.latest_alert.thumb_url : camera.latest_image.thumb_url"
                /> -->
                <thumbs-lazy-image-component
                  v-if="camera.latest_image !== undefined && camera.latest_image.access_url !== ''"
                  class="card-img-top"
                  :src="(camera.latest_alert !== undefined && camera.latest_alert_show) ? camera.latest_alert.access_url : camera.latest_image.access_url"
                />
                <b-img
                  v-else
                  :alt="`${camera.unit_number}-${camera.camera_id}`"
                  fluid
                  class="card-img-top"
                  src="@/assets/images/noImage.png"
                />
              </b-link>
            </div>
            <!-- body -->
            <b-card-body class="pb-1">
              <b-card-title class="mb-0">
                <b-row>
                  <b-col class="col-3 text-right pr-0 text-body">
                    <small>Site:</small>
                  </b-col>
                  <b-col class="col-9 text-body">
                    {{ camera.sitename }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-3 text-right pr-0 text-body">
                    <small>Location:</small>
                  </b-col>
                  <b-col class="col-9 text-body">
                    {{ camera.location_name }}
                  </b-col>
                </b-row>
              </b-card-title>
              <b-card-text>
                <b-row>
                  <b-col class="col-3 text-right pr-0 text-body">
                    <small>Camera:</small>
                  </b-col>
                  <b-col class="col-9 text-body">
                    {{ camera.unit_number }}
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>

            <b-card-footer class="pt-1 pb-1">
              <b-row>
                <b-col class="col-3 text-right pr-0 text-body">
                  <small>Status:</small>
                </b-col>
                <b-col class="col-9 text-body">
                  <b-badge
                    pill
                    :variant="`light-${resolveStatusVariant(camera.is_active, camera.is_online)}`"
                    class="text-capitalize"
                  >
                    {{ resolveStatusVariantText(camera.is_active, camera.is_online) }}
                  </b-badge>
                </b-col>
              </b-row>
              <div v-if="camera.latest_alert !== undefined && camera.latest_alert_show">
                <b-row>
                  <b-col class="col-3 text-right pr-0 text-body">
                    <small>Alert:</small>
                  </b-col>
                  <b-col class="col-9 text-body">
                    <b-badge
                      pill
                      variant="light-danger"
                      class="text-capitalize"
                    >
                      {{ camera.latest_alert.message }}
                    </b-badge>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-3 text-right pr-0 text-body">
                    <small>Alert Date:</small>
                  </b-col>
                  <b-col class="col-9 text-body">
                    <small
                      v-if="camera.latest_alert !== undefined"
                    >{{ camera.latest_alert.time_created.replace('T', ' ') }}</small>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <b-row>
                  <b-col class="col-3 text-right pr-0 text-body">
                    <small>Image Date:</small>
                  </b-col>
                  <b-col class="col-9 text-body">
                    <small
                      v-if="camera.latest_image !== undefined"
                    >{{ camera.latest_image.time_created.replace('T', ' ') }}</small>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-3 text-right pr-0 text-body">
                    <small>Alert:</small>
                  </b-col>
                  <b-col class="col-9 text-body">
                    <b-badge
                      pill
                      variant="light-success"
                      class="text-capitalize"
                    >
                      OK
                    </b-badge>
                  </b-col>
                </b-row>
              </div>
            </b-card-footer>
          </b-card>
        </section>
      </div>
    </template>
    <!-- Cameras List - All Cameras / All Alerts -->
    <section
      v-else
      class="grid-view"
    >
      <b-card
        v-for="camera in cameras"
        :key="camera.camera_id"
        no-body
        :class="camera.latest_alert !== undefined && camera.latest_alert_show && 'border-danger'"
      >
        <div class="item-img text-center">
          <b-link :to="{ name: 'apps-cameraconsole-edit', params: { cameraid: camera.camera_id } }">
            <!-- <b-img-lazy
              v-if="camera.latest_image !== undefined && camera.latest_image.thumb_url !== ''"
              :alt="`${camera.sitename}-${camera.location_name}`"
              blank-src="@/assets/images/noImage.png"
              fluid
              class="card-img-top"
              :src="(camera.latest_alert !== undefined && camera.latest_alert_show) ? camera.latest_alert.thumb_url : camera.latest_image.thumb_url"
            /> -->
            <thumbs-lazy-image-component
              v-if="camera.latest_image !== undefined && camera.latest_image.access_url !== undefined && camera.latest_image.access_url !== ''"
              class="card-img-top"
              :src="(camera.latest_alert !== undefined && camera.latest_alert_show) ? camera.latest_alert.access_url : camera.latest_image.access_url"
            />
            <b-img
              v-else
              :alt="`${camera.unit_number}-${camera.camera_id}`"
              fluid
              class="card-img-top"
              src="@/assets/images/noImage.png"
            />
          </b-link>
        </div>
        <!-- body -->
        <b-link :to="{ name: 'apps-cameraconsole-edit', params: { cameraid: camera.camera_id } }">
          <b-card-body class="pb-1">
            <b-card-title class="mb-0">
              <b-row>
                <b-col class="col-3 text-right pr-0">
                  <small>Site:</small>
                </b-col>
                <b-col class="col-9">
                  {{ camera.sitename }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="col-3 text-right pr-0">
                  <small>Location:</small>
                </b-col>
                <b-col class="col-9">
                  {{ camera.location_name }}
                </b-col>
              </b-row>
            </b-card-title>
            <b-card-text>
              <b-row>
                <b-col class="col-3 text-right pr-0 text-body">
                  <small>Camera:</small>
                </b-col>
                <b-col class="col-9 text-body">
                  {{ camera.unit_number }}
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>

          <b-card-footer class="pt-1 pb-1">
            <b-row>
              <b-col class="col-3 text-right pr-0 text-body">
                <small>Status:</small>
              </b-col>
              <b-col class="col-9">
                <b-badge
                  pill
                  :variant="`light-${resolveStatusVariant(camera.is_active, camera.is_online)}`"
                  class="text-capitalize"
                >
                  {{ resolveStatusVariantText(camera.is_active, camera.is_online) }}
                </b-badge>
              </b-col>
            </b-row>
            <div v-if="camera.latest_alert !== undefined && camera.latest_alert_show">
              <b-row>
                <b-col class="col-3 text-right pr-0 text-body">
                  <small>Alert:</small>
                </b-col>
                <b-col class="col-9">
                  <b-badge
                    pill
                    variant="light-danger"
                    class="text-capitalize"
                  >
                    {{ camera.latest_alert.message }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="col-3 text-right pr-0 text-body">
                  <small>Date:</small>
                </b-col>
                <b-col class="col-9 text-body">
                  <small
                    v-if="camera.latest_alert !== undefined"
                  >{{ camera.latest_alert.time_created.replace('T', ' ') }}</small>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-row>
                <b-col class="col-3 text-right pr-0 text-body">
                  <small>Date:</small>
                </b-col>
                <b-col class="col-9 text-body">
                  <small
                    v-if="camera.latest_image !== undefined"
                  >{{ camera.latest_image.time_created.replace('T', ' ') }}</small>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="col-3 text-right pr-0 text-body">
                  <small>Alert:</small>
                </b-col>
                <b-col class="col-9 text-body">
                  <b-badge
                    pill
                    variant="light-success"
                    class="text-capitalize"
                  >
                    OK
                  </b-badge>
                </b-col>
              </b-row>
            </div>
          </b-card-footer>
        </b-link>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            align="right"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BRow, BCol, BFormInput, BCard, BCardBody, BCardText, BPagination, BCardTitle, BCardFooter, BBadge, BLink, BImg, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
// import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import vSelect from 'vue-select'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import CameraconsoleListFilters from './CameraconsoleListFilters.vue'
// import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useCameraConsoleListTmp'
// import { useEcommerceUi } from '../useCameraConsole'
import useCameraConsoleList from './useCameraConsoleList'
import cameraConsoleStoreModule from '../cameraConsoleStoreModule'
import clientStoreModule from '../../clients/clientStoreModule'
import ThumbsLazyImageComponent from './thumbsLazyImageComponent'
import ability from '../../../../libs/acl/ability'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BRow,
    BCol,
    BFormInput,
    BCard,
    BCardBody,
    BCardText,
    BPagination,
    BCardTitle,
    BCardFooter,
    BBadge,
    BLink,
    BImg,
    BButton,
    vSelect,
    CameraconsoleListFilters,
    ThumbsLazyImageComponent,

  },
  data() {
    return {
      thumb_url: '',
      clientsList: [],
      disableRefresh: this.$store.state.cameraconsole.disableRefresh,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'app-cameraconsole'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, cameraConsoleStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const userData = getUserData()
    let userClientName = null
    if (store.state.userSettings.userSettings.cameraListClientFilter !== undefined && store.state.userSettings.userSettings.cameraListClientFilter !== null) {
      userClientName = store.state.userSettings.userSettings.cameraListClientFilter
    } else if (userData.group === 'Client_Admin' || userData.group === 'Client_Viewer') {
      userClientName = userData.client_name
      store.state.userSettings.userSettings.cameraListClientFilter = userData.client_name
    }

    console.log('USER DATA')
    console.log(userData)
    console.log(userClientName)
    // const userClientName = null

    const viewTypeOptions = [
      { label: 'All Cameras by Site', value: 'cameraBySites' },
      { label: 'All Cameras', value: 'allCameras' },
      { label: 'All Alerts', value: 'allAlerts' },
    ]

    const statusOptions = [
      { label: 'Online', value: 'online' },
      { label: 'Offline', value: 'offline' },
      { label: 'Disabled', value: 'disabled' },
    ]

    // const siteOptions = [
    //   { label: 'All Sites', value: '' },
    // ]

    const {
      cameras,
      fetchItems,
      fetchClientSites,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      refListTable,
      refetchData,

      // UI
      resolveBatteryIcon,
      resolveBatteryColor,
      resolveBatteryVariant,
      resolveSignalIcon,
      resolveSignalColor,
      resolveSignalVariant,
      resolveFirmwareVariant,
      resolveStatusVariant,
      resolveStatusVariantText,

      // Extra Filters
      clientFilter,
      viewTypeFilter,
      siteFilter,
      siteOptions,
      statusFilter,
    } = useCameraConsoleList(userClientName)

    fetchItems(userClientName)
    fetchClientSites()
    console.log('cameras')
    console.log(cameras)

    return {
      userData,
      userClientName,
      cameras,
      fetchItems,
      fetchClientSites,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      refListTable,
      refetchData,
      viewTypeOptions,
      viewTypeFilter,
      statusFilter,
      statusOptions,
      siteOptions,
      siteFilter,
      clientFilter,

      // UI
      resolveBatteryIcon,
      resolveBatteryColor,
      resolveBatteryVariant,
      resolveSignalIcon,
      resolveSignalColor,
      resolveSignalVariant,
      resolveFirmwareVariant,
      resolveStatusVariant,
      resolveStatusVariantText,
    }
  },
  created() {
    console.log('Camera Console List created')
    // Get the list of Clients (should be based on current users access)
    const qvars = {
      forceRefresh: false,
      q: '',
      perPage: 0,
      page: 1,
      sortBy: 'display_name',
      sortDesc: '',
      status: null,
    }
    if (ability.can('read', 'cameras') || ability.can('read', 'clients')) {
      console.log('app-client/fetchClients')
      store
        .dispatch('app-client/fetchClients', qvars)
        .then(response => {
          const { clients } = response.data
          clients.forEach(item => {
            this.clientsList.push({ label: item.display_name, value: item.clientname })
          })
          console.log('clientsList')
          console.log(this.clientsList)
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching clients list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      this.clientsList = []
    }
  },
  methods: {
    refreshTheList() {
      console.log('refreshTheList')
      this.disableRefresh = true
      this.$store.state.cameraconsole.disableRefresh = true
      this.$store.state.cameraconsole.fetchingCameraConsoleList = true
      this.refetchData()
      setTimeout(() => {
        console.log('SETTIMEOUT: camera console list timeout reset')
        this.$store.state.cameraconsole.disableRefresh = false
        this.disableRefresh = false
      }, 20000)
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.per-page-selector {
  width: 90px;
}
</style>
