<template>
  <div>
    <b-row class="mb-1">
      <b-col
        cols="12"
      >
        <b-form-checkbox
          v-model="showFilters"
          name="check-button"
          switch
          inline
        >
          <h5>
            Filters
          </h5>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <!-- Filters Drop-downs -->
    <b-row
      v-if="showFilters"
      class="mb-2"
    >
      <b-col
        cols="3"
        md="3"
        class="mb-md-0 mb-2"
      >
        <label>Client</label>
        <v-select
          :value="clientFilter"
          :options="clientsList"
          class="w-100"
          :reduce="val => val.value"
          @input="(val) => $emit('update:clientFilter', val)"
        />
      </b-col>
      <b-col
        cols="3"
        md="3"
        class="mb-md-0 mb-2"
      >
        <label>View Type</label>
        <v-select
          :value="viewTypeFilter"
          :options="viewTypeOptions"
          class="w-100"
          :reduce="val => val.value"
          @input="(val) => $emit('update:viewTypeFilter', val)"
        />
      </b-col>
      <b-col
        cols="3"
        md="3"
        class="mb-md-0 mb-2"
      >
        <label>Site</label>
        <v-select
          :value="siteFilter"
          :options="siteOptions"
          class="w-100"
          :reduce="val => val.value"
          @input="(val) => $emit('update:siteFilter', val)"
        />
      </b-col>
      <b-col
        cols="3"
        md="3"
        class="mb-md-0 mb-2"
      >
        <label>Status</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="statusFilter"
          :options="statusOptions"
          class="w-100"
          :reduce="val => val.value"
          @input="(val) => $emit('update:statusFilter', val)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    clientFilter: {
      type: [String, null],
      default: null,
    },
    clientsList: {
      type: Array,
      required: true,
    },
    viewTypeFilter: {
      type: [String, null],
      default: null,
    },
    viewTypeOptions: {
      type: Array,
      required: true,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    siteFilter: {
      type: [String, null],
      default: null,
    },
    siteOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showFilters: false,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
