import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useUsersList(userClientName) {
  // Use toast
  const toast = useToast()

  const cameras = ref([])
  const refListTable = ref(null)
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('camera_id')
  const statusFilter = ref(null)
  const siteFilter = ref(null)
  const siteOptions = ref([])
  const clientFilter = ref(userClientName)
  const viewTypeFilter = ref('allCameras')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  console.log('Got the userClientName:')
  console.log(clientFilter.value)
  // Re-Setup Filters
  if (store.state.userSettings.userSettings.cameraListViewTypeFilter !== null) {
    viewTypeFilter.value = store.state.userSettings.userSettings.cameraListViewTypeFilter
  }
  if (store.state.userSettings.userSettings.cameraListSiteFilter !== null) {
    siteFilter.value = store.state.userSettings.userSettings.cameraListSiteFilter
  }
  if (store.state.userSettings.userSettings.cameraListStatusFilter !== null) {
    statusFilter.value = store.state.userSettings.userSettings.cameraListStatusFilter
  }

  const fetchItems = () => {
    console.log('fetchItems')
    if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
      const qvars = {
        forceRefresh: store.state.cameraconsole.fetchingCameraConsoleList,
        userClientName: clientFilter.value,
        sitename: siteFilter.value,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        status: statusFilter.value,
        viewType: viewTypeFilter.value,
      }
      console.log(qvars)
      store
        .dispatch('app-cameraconsole/fetchCameras', qvars)
        .then(response => {
          if (viewTypeFilter.value === 'cameraBySites') {
            // reformat data so that we can have Site Headings
            const finalCameraArr = []
            const respCameras = response.data.cameras
            // Loop through and find all the sites
            respCameras.forEach(item => {
              const { sitename } = item
              const obj = finalCameraArr.find(o => o.sitename === sitename)
              if (typeof obj === 'undefined') {
                const tmp = { sitename }
                finalCameraArr.push(tmp)
              }
            })
            // Loop through and put in all the cameras
            finalCameraArr.forEach((siteItems, siteIndex) => {
              const tmpArr = []
              respCameras.forEach(item => {
                if (siteItems.sitename === item.sitename) {
                  tmpArr.push(item)
                }
              })
              finalCameraArr[siteIndex].cameras = tmpArr
            })
            cameras.value = finalCameraArr
          } else {
            cameras.value = response.data.cameras
          }
          totalItems.value = response.data.totalItems
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching cameras list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  const fetchClientSites = () => {
    console.log('fetchClientSites')
    console.log(clientFilter.value)
    const qvars = {
      forceRefresh: false,
      q: '',
      perPage: 0,
      page: 1,
      sortBy: 'sitename',
      sortDesc: false,
      status: 'active',
      clientname: clientFilter.value,
    }
    console.log(qvars)
    store
      .dispatch('app-client/fetchClientSites', qvars)
      .then(response => {
        console.log('fetchClientSites Response')
        console.log(response.data)
        siteOptions.value = [
          { label: 'All Sites', value: '' },
        ]
        const { clientSites } = response.data
        clientSites.forEach(item => {
          siteOptions.value.push({ label: item.sitename, value: item.sitename })
        })
        console.log(siteOptions.value)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching client sites list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    fetchItems()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, siteFilter, clientFilter, viewTypeFilter], (newVal, oldVal) => {
    console.log('watch')
    if (newVal[3] !== oldVal[3]) {
      // eslint-disable-next-line prefer-destructuring
      store.state.userSettings.userSettings.cameraListStatusFilter = newVal[3]
    }
    if (newVal[4] !== oldVal[4]) {
      // eslint-disable-next-line prefer-destructuring
      store.state.userSettings.userSettings.cameraListSiteFilter = newVal[4]
    }
    if (newVal[5] !== oldVal[5]) {
      // Changing client filter
      console.log('store.state.userSettings')
      console.log(store.state.userSettings)
      console.log(newVal[5])
      // eslint-disable-next-line prefer-destructuring
      store.state.userSettings.userSettings.cameraListClientFilter = newVal[5]
      siteFilter.value = null
      fetchClientSites()
    }
    if (newVal[6] !== oldVal[6]) {
      // eslint-disable-next-line prefer-destructuring
      store.state.userSettings.userSettings.cameraListViewTypeFilter = newVal[6]
    }
    fetchItems()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveBatteryIcon = batteryLife => {
    // determine which battery icon to use
    if (batteryLife === 10) {
      // set icon
    }
    return ['fas', 'battery-full']
  }

  const resolveBatteryColor = batteryLife => {
    let myRet = { color: 'white' }
    if (batteryLife <= 10) {
      myRet = { color: 'red' }
    } else if (batteryLife > 10 && batteryLife <= 10.8) {
      myRet = { color: 'orange' }
    } else if (batteryLife > 10.8 && batteryLife <= 11.7) {
      myRet = { color: 'blue' }
    } else if (batteryLife > 11.7) {
      myRet = { color: 'green' }
    }
    return myRet
  }

  const resolveBatteryVariant = batteryLife => {
    let myRet = batteryLife / 1
    myRet = myRet.toFixed(2)
    return `${myRet} v`
  }

  const resolveSignalIcon = batteryLife => {
    // determine which signal icon to use
    if (batteryLife === 10) {
      // set icon
    }
    return ['fas', 'signal']
  }

  const resolveSignalColor = antennaSignal => {
    let myRet = { color: 'white' }
    if (antennaSignal < -100) {
      myRet = { color: 'red' }
    } else if (antennaSignal < -90) {
      myRet = { color: 'orange' }
    } else if (antennaSignal < -80) {
      myRet = { color: 'yellow' }
    } else {
      myRet = { color: 'green' }
    }
    return myRet
  }

  const resolveSignalVariant = antennaSignal => `${antennaSignal} dBm`

  const resolveFirmwareVariant = firmwareVer => {
    let myRet = firmwareVer / 1
    myRet = myRet.toFixed(2)
    return `${myRet}`
  }

  const resolveStatusVariant = (status, online) => {
    if (online === false) return 'warning'
    if (status === 'pending') return 'warning'
    if (status === true) return 'success'
    if (status === false) return 'secondary'
    return 'success'
  }

  const resolveStatusVariantText = (status, online) => {
    if (online === false) return 'Offline'
    if (status === 'pending') return 'Warning'
    if (status === true) return 'Online'
    if (status === false) return 'Disabled'
    return 'Online'
  }

  return {
    cameras,
    fetchItems,
    fetchClientSites,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    refListTable,

    resolveBatteryIcon,
    resolveBatteryColor,
    resolveBatteryVariant,
    resolveSignalIcon,
    resolveSignalColor,
    resolveSignalVariant,
    resolveFirmwareVariant,
    resolveStatusVariant,
    resolveStatusVariantText,
    refetchData,

    // Extra Filters
    statusFilter,
    siteFilter,
    siteOptions,
    clientFilter,
    viewTypeFilter,
  }
}
